<template>
  <div class="container">
    <div class="page-title">
      <h3>网络设备详情
        <el-divider direction="vertical"></el-divider>
        <span @click="$router.go(-1)">返回</span>
      </h3>
    </div>
    <div class="wrap">
      <el-row class="row">
        <el-col :span="6"><span>设备名称：</span>{{devInfo.name}}</el-col>
        <el-col :span="6"><span>位置：</span>{{devInfo.roomname}} / {{devInfo.cabname}}</el-col>
        <el-col :span="6"><span>类型：</span>{{devInfo.btype}} / {{devInfo.stype}}</el-col>
        <el-col :span="6"><span>管理者：</span>{{devInfo.manager}}</el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="6"><span>品牌：</span>{{devInfo.brand}}</el-col>
        <el-col :span="6"><span>型号：</span>{{devInfo.model_no}}</el-col>
        <el-col :span="6"><span>序列号：</span>{{devInfo.sn_no}}</el-col>
        <el-col :span="6"><span>固定资产编号：</span>{{devInfo.asset_no}}</el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="6"><span>信创产品：</span><i v-if="devInfo.made_inchina==1">是</i></el-col>
        <el-col :span="6"><span>金额：</span>{{devInfo.price}}</el-col>
        <el-col :span="6"><span>开始使用时间：</span>{{devInfo.start_usetime}}</el-col>
        <el-col :span="6"><span>维保到期时间：</span>{{devInfo.main_time}}</el-col>
      </el-row>
      <el-row class="row">
        <el-col :span="6"><span>IP：</span>{{devInfo.ip}}</el-col>
        <el-col :span="6"><span>开放端口：</span>{{devInfo.safe_ports}}</el-col>
        <el-col :span="6"><span>覆盖网段：</span>{{devInfo.safe_sys}}</el-col>
        <el-col :span="6"><span>备注：</span>{{devInfo.note}}</el-col>
      </el-row>

      <div class="tab-wrap">
        <el-button icon="el-icon-plus" type="text" @click.prevent="addDev" class="add-btn" v-if="activeName=='dev'">
          添加关联设备
        </el-button>
        <el-button icon="el-icon-plus" type="text" @click.prevent="addInterface" class="add-btn" v-if="activeName=='interface'">
          为该设备添加接口
        </el-button>
        <el-tabs v-model="activeName" type="card" class="tab" @tab-click="switchTab">
          <el-tab-pane label="关系图谱" name="map">
            <div ref="graphChart" style="width:100%;height: 600px"></div>
          </el-tab-pane>
          <el-tab-pane label="接口信息" name="interface">
            <hw-ref-interface :dev-id="id" :cid="devInfo.type_id" ref="refinterface"
                               v-if="activeName=='interface'"></hw-ref-interface>
          </el-tab-pane>
          <el-tab-pane label="关联设备" name="dev">
            <hw-ref-devicelist :dev-id="id" :cid="devInfo.type_id" :type="devInfo.type" ref="refdev"
                               v-if="activeName=='dev'"></hw-ref-devicelist>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
  import * as echarts from 'echarts';
  import RefDevicelist from "../components/pages/RefDevicelist";
  import RefInterface from "../components/pages/RefInterface";

  const option = {
    legend: [{
      //selectedMode: 'single'
    }],
    animationDuration: 1500,
    animationEasingUpdate: 'quinticInOut',

    series: [
      {
        //name: '关系图',
        type: 'graph',
        legendHoverLink: false, //启用图例hover联动高亮
        layout: 'force', //采用力引导布局
        selectedMode: 'multiple',
        select: {
          label: {
            show: true
          },
          // edgeLabel: {
          //     show: true
          // }
        },
        data: [],
        links: [],
        categories: [
          {
            "name": "主机"
          },
          {
            "name": "安全设备"
          },
          {
            "name": "网络设备"
          },
          {
            "name": "其他设备"
          },
          {
            "name": "机房机柜"
          }
        ],
        roam: true, //开启缩放和平移
        label: {
          show: true,
          position: 'right',
          formatter: '{b}'
        },
        //连接线标签
        edgeLabel: {
          show: true,
          color: '#f30',
          fontWeight: 'bold',
          fontSize: 16,
          formatter: '{c}'
        },
        edgeSymbol: ['circle', 'arrow'],
        // edgeSymbolSize: [4, 10],
        force: {
          repulsion: 800,
          edgeLength: [150, 200],
          layoutAnimation: false,
          friction: 1, //这个参数能减缓节点的移动速度。取值范围 0 到 1。
        },
        lineStyle: {
          color: 'source',
          width: 2,
          curveness: 0.1
        },
        scaleLimit: {
          min: 0.5,
          max: 3
        },
        emphasis: {
          focus: 'adjacency',
          lineStyle: {
            width: 10
          },
          label: {
            show: true
          }
        },
        labelLayout: {
          hideOverLap: true,
          moveOverlap: 'shiftY'
        },
        tooltip: {
          formatter: '{b}'  //c:数值
        }
      }
    ]
  };

  export default {
    name: "NetworkDetail",
    components: {
      hwRefDevicelist: RefDevicelist,
      hwRefInterface: RefInterface
    },
    data() {
      return {
        id: parseInt(this.$route.query.id),
        devInfo: {},
        loading: true,
        activeName: 'map',
      }
    },
    created() {
      this.loadDetail();
    },
    beforeRouteLeave(to, from, next) {
      this.clearChart();
      next();
    },
    methods: {
      createChart() {
        const myChart = echarts.init(this.$refs.graphChart);
        myChart.showLoading();
        //myChart.setOption(option, true);
        let dev = [
          {
            id: "dev_" + this.devInfo.id,
            name: this.devInfo.name,
            symbolSize: 50,
            value: 50,
            category: 2
          },
          {
            id: "room_" + this.devInfo.room_id,
            name: this.devInfo.roomname + '/' + this.devInfo.cabname,
            symbolSize: 40,
            category: 4
          }
        ];
        dev[0].label = {
          show: true,
          fontWeight: 'bold',
          fontSize: '18px'
        }
        let links = [{
          source: "dev_" + this.devInfo.id,
          target: 'room_' + this.devInfo.room_id,
          value: '',
          symbol: 'none'
        }];
        this.$get(this.$api.GetDeviceChart, {id: this.$route.query.id}).then((res) => {
          myChart.hideLoading();
          let arr = res.nodes;
          let nodes = [...dev, ...arr];
          option.series[0].data = nodes;
          option.series[0].links = [...links, ...res.links];
          myChart.setOption(option);
        });
      },
      clearChart() {
        const myChart = echarts.init(this.$refs.graphChart);
        //myChart.clear();
        option.series[0].data = [];
        myChart.setOption(option);
        myChart.dispose(); //销毁实例
      },
      loadDetail() {
        this.$get(this.$api.GetDeviceInfo, {id: this.id}).then((res) => {
            this.devInfo = res.data;
            this.createChart();
        });
      },

      switchTab(tab) {
        if (tab.name == 'map') {
          this.$nextTick(() => {
            this.createChart();
          });
        }
      },
      addDev() {
        this.$refs.refdev.showPop();
      },
      //添加接口
      addInterface() {
        this.$refs.refinterface.showPop();
      }
    }
  }
</script>

<style scoped lang="less">
  .tab-wrap {
    position: relative;

    .add-btn {
      position: absolute;
      right: 0px;
      top: 0px;
      z-index: 999;
    }
  }

  .row {
    margin-top: 20px;
    padding: 4px 0;

    span {
      color: #888;
    }

    i {
      font-style: normal;
    }
  }

  .tab {
    margin-top: 20px;
  }

  .pagenation {
    margin: 20px auto;
    text-align: center;
  }

</style>
